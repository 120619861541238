<template>
    <div class="product-grid p-3">
        <div class="d-flex flex-column px-3">
            <div class="ss-header mb-3">
                <span class="title-desc"><strong>Смена пароля</strong></span>
            </div>
            <ValidationObserver slim v-slot="{ invalid, handleSubmit }">
                <form class="request-form" @submit.prevent="handleSubmit(requestChangePassword)">
                    <ValidationProvider :name="form.old_password" rules="required|min:4" v-slot="{ errors }">
                        <div class="form-group">
                            <label class="request-form__label" for="old_password">Старый пароль <i class="request">*</i></label>
                            <input type="password" class="form-control" id="old_password" v-model="form.old_password" name="old_password" minlength="4" required>
                        </div>
                        <span v-if="errors" class="d-block my-2">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <ValidationProvider :name="form.new_password" rules="required|min:4" v-slot="{ errors }">
                        <div class="form-group">
                            <label class="request-form__label" for="new_password">Новый пароль <i class="request">*</i></label>
                            <input type="password" class="form-control" id="new_password" v-model="form.new_password" name="new_password" minlength="4" required>
                        </div>
                        <span v-if="errors" class="d-block my-2">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <ValidationProvider :name="form.confirm_password" rules="required|min:4" v-slot="{ errors }">
                        <div class="form-group">
                            <label class="request-form__label" for="confirm_password">Подтверждение нового пароля <i class="request">*</i></label>
                            <input type="password" class="form-control" id="confirm_password" v-model="form.confirm_password" name="confirm_password" minlength="4" required>
                        </div>
                        <span v-if="errors" class="d-block my-2">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div class="container-left">
                        <button class="btn request-form-btn" type="submit" :disabled="invalid">
                            Отправить
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import {post} from '@/lib/axios';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {showSuccess} from "@/lib/swal2";

export default {
    name: "ChangePassword",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data: () => ({
        form: {
            old_password: '',
            new_password: '',
            confirm_password: '',
        },
    }),
    methods: {
        async requestChangePassword() {
            await post('account/change-password', {data: this.form}).then(response => {
                if (response.data.error) {
                    showSuccess(response.data.status, response.data.message)
                } else {
                    showSuccess(response.data.message)
                }
            });
        }
    },
    metaInfo() {
        return {
            title: `Профиль - Смена пароля - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped lang="scss">
.ss-header {
    padding: 20px 0;
    border-bottom: 2px solid #f5f5f5;
}

.request-form__label {
    display: inline-block;
}

.request-form-btn {
    text-align: center;
    display: inline-block;
    background: #d2233c;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font: 700 16px/24px Futuris;
    min-width: 275px;
    margin-top: 20px;
    padding: 10px;
    border: 0;
    cursor: pointer;

    &:disabled {
        background-color: #ccc;
    }
}
</style>
