var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-grid p-3"},[_c('div',{staticClass:"d-flex flex-column px-3"},[_vm._m(0),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"request-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.requestChangePassword)}}},[_c('ValidationProvider',{attrs:{"name":_vm.form.old_password,"rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"request-form__label",attrs:{"for":"old_password"}},[_vm._v("Старый пароль "),_c('i',{staticClass:"request"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.old_password),expression:"form.old_password"}],staticClass:"form-control",attrs:{"type":"password","id":"old_password","name":"old_password","minlength":"4","required":""},domProps:{"value":(_vm.form.old_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "old_password", $event.target.value)}}})]),(errors)?_c('span',{staticClass:"d-block my-2"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.form.new_password,"rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"request-form__label",attrs:{"for":"new_password"}},[_vm._v("Новый пароль "),_c('i',{staticClass:"request"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.new_password),expression:"form.new_password"}],staticClass:"form-control",attrs:{"type":"password","id":"new_password","name":"new_password","minlength":"4","required":""},domProps:{"value":(_vm.form.new_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "new_password", $event.target.value)}}})]),(errors)?_c('span',{staticClass:"d-block my-2"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.form.confirm_password,"rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"request-form__label",attrs:{"for":"confirm_password"}},[_vm._v("Подтверждение нового пароля "),_c('i',{staticClass:"request"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirm_password),expression:"form.confirm_password"}],staticClass:"form-control",attrs:{"type":"password","id":"confirm_password","name":"confirm_password","minlength":"4","required":""},domProps:{"value":(_vm.form.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirm_password", $event.target.value)}}})]),(errors)?_c('span',{staticClass:"d-block my-2"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"container-left"},[_c('button',{staticClass:"btn request-form-btn",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" Отправить ")])])],1)]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ss-header mb-3"},[_c('span',{staticClass:"title-desc"},[_c('strong',[_vm._v("Смена пароля")])])])}]

export { render, staticRenderFns }